<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-nav current="0"></service-support-nav>
        <div>
          <img src="../assets/images/service-support/technical-state-banner.png" alt="" class="img">
        </div>
        <div class="container-1646 pt40">
          <div class="flex">
          <div style="" class="support-menu">
        <el-menu
        default-active="1-1"
        class="el-menu-vertical-demo"
        active-text-color="#71B581">
        <el-sub-menu index="1">
          <template #title>
            <span>技术服务</span>
          </template>
            <el-menu-item index="1-1" @click="isShow('1-1')">服务热线</el-menu-item>
            <el-menu-item index="1-2" @click="isShow('1-2')">机房项目运作</el-menu-item>
            <el-menu-item index="1-3" @click="isShow('1-3')">技术安装与培训</el-menu-item> 
        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <span>状态服务</span>
          </template>
            <el-menu-item index="2-1" @click="isShow('2-1')">技术安装与培训</el-menu-item>
            <el-menu-item index="2-2" @click="isShow('2-2')">产品追踪服务</el-menu-item>
            <el-menu-item index="2-3" @click="isShow('2-3')">产品保修</el-menu-item>
        </el-sub-menu>
      </el-menu>
       </div> 
       <div v-html="supportVal"  class="support-val"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import ServiceSupportNav from '@/components/ServiceSupportNav'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner,
      ServiceSupportNav
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        supportVal:'<div>公司提供7×24小时电话服务，方便用户咨询产品、技术、业务、服务等问题，用户可随时拨通 热线，方便您对产品、技术、业务需求、服务等选购，使用、维护过程中遇到的各类问题。 热线电话：010-81333426<br>  热线客服工作时间：周一至周五 9：00-12：00，13：00-18：00</div>',
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
   
      const isShow = (val) => {
        switch (val) { 
          case '1-1':
          useData.supportVal = '<div>公司提供7×24小时电话服务，方便用户咨询产品、技术、业务、服务等问题，用户可随时拨通 热线，方便您对产品、技术、业务需求、服务等选购，使用、维护过程中遇到的各类问题。 热线电话：010-81333426<br>  热线客服工作时间：周一至周五 9：00-12：00，13：00-18：00</div>';
          break;
          case '1-2':
          useData.supportVal = '<div>针对您具体项目的需求，可提供包括了解需求、推荐需求、现场勘测、方案设计、远程技术指 导等整个过程的服务承诺。</div>';
            break;
          case '1-3':
          useData.supportVal = '<div>可根据您选购的产品派遣技术工程师进行现场安装调试，以及现场用户操作、运行、维护和对 故障排除等的免费技术培训，确保用户对产品进行安全、科学、规范的操作，最大限度的为客户带来快速体验与优质享受。</div>';
            break;
            case '2-1':
          useData.supportVal = '<div>可一对一提供产品在制过程与生产状态跟踪与反馈，可针对您的临时变更进行合理的调整与建 议改善。可应用户合同要求提供相关检测报告或材料报告，提供出厂报告和出厂检验合格证。 提供齐全的产品技术图纸及产品样本等技术资料。</div>'
            break;
          case '2-2':
            useData.supportVal = '<div>为每个产品客户建立档案，并提供年度的跟踪与维护保养等更精确、更讯捷的专业服务。</div>'
            break;
          default:
          useData.supportVal = '<div>UPS电源、蓄电池产品自安装之日起非人为或自然灾害外，三年保修；三年内免费上门服务！ 精密空调保修为一年，自安装之日起计算；冷通道产品提供一年免费质保。</div>'
        }
       }
    return {
        ...toRefs(useData),
      goTo,
      go,
      isShow,
    }
  }
}
  </script>
  <style scoped>
.support-menu{
  width:328px ;font-size: 30px;
}
.support-val{
  font-size:20px ;width: 1209px;line-height: 40px;margin: 50px auto;
  min-height: 500px;
}
.el-menu-item.is-active {
  background: #E9FFF2;
  border-right: 4px solid #6BB17B;
}
.active{
  color: #999;
}
</style>

  